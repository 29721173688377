@use "custom" as *;
@use "./components/mixins" as *;

.verify-successful-log-in-box {
  @include ContentBox;
}

.verify-successful-log-in-inner-box {
  padding: 1rem 8rem 3rem;
  @include ContentInnerBox;
}

.verify-successful-log-in-title {
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    @include iconStyle;
    width: 3.5rem;
    height: 3.5rem;
    margin-bottom: 1.5rem;
    background-color: $green;

    i {
      font-size: 2rem;
    }
  }

  h3 {
    font-family: cobane;
    font-weight: 600;
    font-size: 2rem;
    color: $dark;
    margin-bottom: 0;
  }
}
