@use "../custom" as *;

.form-input-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .label {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0;
        color: #626762;
        font-family: cobane;
        font-size: 0.875rem;
        margin-bottom: 0.2rem;

        input {
            background-color: rgb(251, 251, 250);
            color: #1f261e;
            font-family: cobane;
            font-weight: 400;
            font-size: 1.1rem;
            background-color: #fff;
            height: 3.5rem;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            appearance: none;
            border-radius: 0.7rem;
            line-height: 1.5;
            padding: 0.375rem 0.75rem;

            @include media-breakpoint-down(lg) {
                height: 51px;
                font-size: 14px;
                padding: 0 14px;
                line-height: 18px;
            }
            
        }

        input:focus {
            outline: 1px solid #4fb518;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }
    }

    .form-error {
        font-size: 14px;
        color: #B30000;
        align-self: flex-start;
    }

}



/* For option selects */
.option-select-label {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: grey;
    position: relative;
  }
  
  .option-select-label select {
    width: 100%;
    height: 55px;
    padding: 0 20px;
    font-size: 18px;
    border-radius: 10px;
    background-color: white;
    border: 1px solid grey;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  
  .option-select-label select:focus {
    outline: 0.5px solid #4FB518;
    border: none;
  }
  
  .option-select-label .items-per-page-icon {
    position: absolute;
    top: 46px;
    right: 20px;
  }
  
.option-select-label .option-select-errors {
  font-size: 12px;
  margin: 0;
  color: #B30000;
}
  



// Date filter input
.date-filter-input {
  height: 36px;
  width: 200px;
  border-radius: 4px;
  color: #cccccc;
  padding: 0 2px;
  border: 1px solid #cccccc;
}