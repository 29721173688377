@use 'custom'as *;
@use './components/mixins'as *;

.verify-phone-number-box {
    @include ContentBox;
}

.verify-phone-number-inner-box{        
    padding: 1rem 8rem 5rem;
    @include ContentInnerBox;
}

.verify-phone-number-title{
    display: flex;
    flex-direction: column;
    align-items: center;

    span{
        @include iconStyle;
        width: 3.5rem;
        height: 3.5rem;
        margin-bottom: 1.5rem;
        background-color: $green;
    }
}

