@use "custom" as *;
@use "./components/mixins" as *;

.dashboard-logo {
  display: block;
  max-width: 90%;
  width: auto;
  height: auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-right: 1rem;

  @include media-breakpoint-down(md) {
    max-width: 45%;
    margin-top: 0rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
}

.dashboard-sidebar-link {
  margin-bottom: 1rem;

  div {
    border-radius: 0.7rem;
    transition: all 0.15s ease-in-out;
    -webkit-transition: all 0.15s ease-in-out;
    -moz-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    -ms-transition: all 0.15s ease-in-out;

    svg {
      fill: $dark-50;
      transition: inherit;
      -webkit-transition: inherit;
      -moz-transition: inherit;
      -o-transition: inherit;
      -ms-transition: inherit;
    }

    span {
      transition: inherit;
      -webkit-transition: inherit;
      -moz-transition: inherit;
      -o-transition: inherit;
      -ms-transition: inherit;
    }
  }

  &:hover {
    div {
      background-color: #edf8e8;
      svg {
        fill: $dark-green;
      }

      span {
        color: $dark-green;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    margin-bottom: 0rem;
    // height: 100%;

    div {
      height: 100%;
      border-radius: 0rem;
      border-top: 2px solid rgba($primary, 0);
      svg {
        fill: $dark-50;
      }
    }

    &:hover {
      div {
        background-color: #fff;
        svg {
          fill: $primary;
        }

        span {
          color: $primary;
        }
      }
    }
  }
}

.selected {
  div {
    background-color: #edf8e8;
    svg {
      fill: $dark-green;
    }

    span {
      color: $dark-green;
    }
  }

  @include media-breakpoint-down(md) {
    div {
      border-top: 2px solid rgba($primary, 1);
      background-color: #fff;
      svg {
        fill: $primary;
      }

      span {
        color: $primary;
      }
    }
  }
}

.dashboard-sidebar-text {
  color: $dark-50;
  font-family: cobane;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.4rem;

  @include media-breakpoint-down(md) {
    color: $dark-50;
    font-family: cobane;
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
  }
}
