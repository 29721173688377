@use "../custom" as *;

.btn-progress {
  margin-top: 2.5rem;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  width: 100%;
  background-color: $green;
  border: 0.1rem solid rgba($green, 0.6);
  color: $white;
  border-radius: 0.75rem;
  font-family: cobane;
  font-size: 1rem;
  line-height: 1.3rem;
  position: relative;

  @include media-breakpoint-down(lg) {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  @include media-breakpoint-down(md) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.btn-primary {
  
  &:hover {
    color: $white;
    background: #3c8713;
    border-color: rgba(#3c8713, 0.6);
  }

  &:focus {
    color: $white;
  }

  &:disabled{
    color: $white;
  }

}

.btn-general {
  padding: 0.7rem 1rem;
  margin-right: 1.5rem;
  background-color: $green;
  border: 0.1rem solid rgba($green, 0.6);
  color: $white;
  border-radius: 0.75rem;
  font-family: cobane;
  font-weight: 400;
  font-size: 1rem;
  position: relative;

  &:hover {
    color: $white;
  }

  @include media-breakpoint-down(lg) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  @include media-breakpoint-down(md) {
    padding: 0.5rem 1rem;
  }
}

.btn-alt-general {
  padding: 0.7rem 1rem;
  margin: auto 1rem 1rem auto;
  border: 0.1rem solid $green;
  background-color: rgba($white, 0);
  color: $green;
  border-radius: 0.75rem;
  font-family: cobane;
  font-weight: 400;
  font-size: 1rem;
  position: relative;

  @include media-breakpoint-down(lg) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  @include media-breakpoint-down(md) {
    padding: 0.5rem 1rem;
  }
}

.btn-outline-primary {
  padding: 0.7rem 1rem;
  margin: auto 1rem 1rem auto;
  border: 0.1rem solid $green;
  background-color: rgba($white, 0);
  color: $green;
  border-radius: 0.75rem;
  font-family: cobane;
  font-weight: 400;
  font-size: 1rem;
  position: relative;

  @include media-breakpoint-down(lg) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  @include media-breakpoint-down(md) {
    padding: 0.5rem 1rem;
  }

  &:hover {
    color: white;
  }
}

.close-button-content-box {
  margin-top: 3.5%;
  margin-right: 2.5%;
  font-size: 1.3rem;

  @include media-breakpoint-down(md) {
    margin-top: 5.5%;
    margin-right: 4.5%;
  }
}

.dashboard-disable-button {
  padding: 0.7rem 1rem;
  border: 0.1rem solid $red;
  background-color: rgba($white, 0);
  color: $red;
  border-radius: 0.75rem;
  font-family: cobane;
  font-weight: 400;
  font-size: 1rem;
  position: relative;

  @include media-breakpoint-down(lg) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  @include media-breakpoint-down(md) {
    padding: 0.5rem 1rem;
  }
}

.btn-outline-danger {
  padding: 0.7rem 1rem;
  border: 0.1rem solid $red;
  background-color: rgba($white, 0);
  color: $red;
  border-radius: 0.75rem;
  font-family: cobane;
  font-weight: 400;
  font-size: 1rem;
  position: relative;

  @include media-breakpoint-down(lg) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  @include media-breakpoint-down(md) {
    padding: 0.5rem 1rem;
  }
}

.btn-outline-secondary {
  padding: 0.7rem 1rem;
  border-width: 0.1rem;
  border-style: solid;
  border-radius: 0.75rem;
  font-family: cobane;
  font-weight: 400;
  font-size: 1rem;
  position: relative;

  @include media-breakpoint-down(lg) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  @include media-breakpoint-down(md) {
    padding: 0.5rem 1rem;
  }
}
