@use "custom" as *;
@use "./components/mixins" as *;


.table-options-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .download-records {
        padding: 1rem 0 1rem 1rem;
    }

    .table-options {
        padding: 1rem;
        display:  flex;
        justify-content: flex-end;
        gap: 1rem;

        .option { 
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        @include media-breakpoint-down(lg) {
            margin-top: 1rem;
            margin-bottom: 0.5rem;
            margin-right: 0;
            flex-direction: column;
        }
    }

    @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
}


.table-options {
    padding: 1rem;
    display:  flex;
    justify-content: flex-end;
    gap: 1rem;

    .option { 
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    @include media-breakpoint-down(lg) {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        margin-right: 0;
        flex-direction: column;
    }
}
