$green: #4fb518;
$gray-100: #f9faf9;
$blue: #186ce9;
$green-20: #dcf0d1;
$green-5: #f6fbf3;
$blue-20: #d1e2fb;
$dark-green: #31581c;
$dark-70: #626762;
$dark-50: #8f928e;
$dark-25: #c7c9c7;
$dark-10: #e9e9e9;
$dark: #1f261e;
$orange: #e98918;
$dark-green-15: #E0E6DD;
$dark-green-10: #eaeee8;
$dark-green-5: #f5f7f4;
$red: #c80707;
$red-5: #fcf3f3;
$orange-5: #fef9f3;

$primary: $green;
$success: $green;
$warning: $orange;
$danger: $red;
$dark: $dark;
$border-radius-lg: 0.7rem;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 5,
  7: $spacer * 7,
  8: $spacer * 10,
  9: $spacer * 12,
  10: $spacer * 15,
  11: $spacer * 17,
  12: $spacer * 20,
  13: $spacer * 23,
  14: $spacer * 25,
  15: $spacer * 27,
  16: $spacer * 30,  
);

$enable-negative-margins: true;

$accordion-padding-y: 1.5rem;
$accordion-padding-x: 1rem;
$accordion-icon-color: $primary;
$accordion-color: $dark;
$accordion-bg: #f7f7f7;
$accordion-border-color: rgba(#fff, 0);
$accordion-border-radius: 1.5rem;

$tooltip-font-size:                 0.7rem;
$tooltip-max-width:                 15rem;


::-moz-selection {
  background: $primary;
  color: white;
}
::-webkit-selection {
  background: $primary;
  color: white;
}
::selection {
  background: $primary;
  color: white;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@import "./node_modules/bootstrap/scss/bootstrap.scss";


