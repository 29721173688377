@use "custom" as *;
@use "./components/mixins" as *;

hr{
  clear:both;
}

.container-fluid {
  padding-right: 0;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.dashboard-sort-param {
  font-family: cobane;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  color: $dark-50;
  padding: 0.5rem;
  border: 1px solid #e0e6dd;
  margin-right: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  .fa.fa-chevron-down {
    font-size: 0.6rem;
    margin-left: 0.25rem;
  }

  &:hover {
    border-color: #98ab8d;
  }
}

// #status-sort-param {
//   position: relative;
//   &:hover {
//     .dashboard-sort-param {
//       border-color: $primary;
//       background: $green-5;
//     }

//     #status-filter-dropdown-content {
//       display: block;
//     }
//   }
// }
// #status-filter-dropdown-content {
//   top: -0.5rem;
//   // transform: translateX(1rem);
// }

.row {
  margin-left: 0;
  margin-right: 0;
}

.row > * {
  padding-left: 0;
  @include media-breakpoint-down(md) {
    padding-right: 0;
  }
}

.dashboard-common-text {
  color: $dark-50;
  font-family: cobane;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.3rem;
}

.modal-content {
  border-radius: 1.5rem;
  background: $gray-100;
}

.fa-lock {
  color: inherit;
  font-size: 1.2rem;
}

.label-radio-checked {
  color: $dark;
  border: 1px solid rgba($primary, 1);
  border-radius: 0.7rem;
  background-color: $green-20;
}

.label-radio {
  cursor: pointer;
  background-color: white;
  border-radius: 0.7rem;
  border: 1px solid rgba(#98ab8d, 0);
  color: #8f928e;

  &:hover {
    border: 1px solid rgba($green-5, 1);
    background-color: $green-5;
  }
}

.fund-wallet-radio-checked {
  svg {
    fill: $dark;
  }

  color: $dark;

  & > div {
    border: 1px solid rgba($primary, 1);
    border-radius: 0.7rem;
  }
  div {
    background-color: $green-20;
  }
}

.fund-wallet-radio {
  cursor: pointer;

  div {
    background-color: white;
  }

  & > div {
    border-radius: 0.7rem;
    border: 1px solid rgba(#98ab8d, 0);
    background-color: white;
  }

  svg {
    fill: #8f928e;
  }

  &:hover {
    & > div {
      border: 1px solid rgba($green-5, 1);
    }
    div {
      background-color: $green-5;
    }
  }
}

.dashboard-tiny-text {
  font-family: cobane;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  color: $dark-50;
  text-decoration: none;
}

.dashboard-header-text {
  color: $dark;
  font-family: cobane;
  font-weight: 500;
  font-size: 2rem;

  @include media-breakpoint-down(md) {
    font-size: 1.5rem;
  }
}

.dashboard-header-support-title {
  color: $dark;
  font-family: cobane;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.dashboard-card {
  border: 1px solid #e0e6dd;
  border-radius: 1.5rem;
  padding: 1.2rem;
  margin: 1rem auto;
  height: 80%;

  span {
    svg {
      margin-bottom: 3rem;
    }
  }

  @include media-breakpoint-down(md) {
    height: auto;
    margin: 0.5rem 0.5rem;
    padding: 0.8rem;

    span {
      svg {
        margin-bottom: 1rem;
      }
    }
  }
}

.dashboard-content-box {
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding: 1.2rem 1rem;
  background-color: $gray-100;
  border-radius: 1.5rem;
  height: 80%;

  @include media-breakpoint-up(lg){
    padding: 1.5rem 1.6rem;
  }

}

.dashboard-link {
  font-family: cobane;
  font-size: 1rem;
  line-height: 1.3rem;
  color: $green;
  text-decoration: none;
}

.dashboard-section-heading {
  color: $dark;
  font-family: cobane;
  font-size: 1.25rem;
  font-weight: 500;
}

.dashboard-little-heading {
  color: $dark;
  font-family: cobane;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.3rem;
}

.dashboard-fund-wallet-inner-box {
  padding: 0rem 3.5rem;

  @include media-breakpoint-down(lg) {
    padding: 0rem 2rem;
  }

  @include media-breakpoint-down(md) {
    padding: 0rem 0.5rem;
  }
}

.dashboard-fund-wallet-title {
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    @include iconStyle;
    width: 3.5rem;
    height: 3.5rem;
    margin-bottom: 1.5rem;
    background-color: #dcf0d1;
  }

  h3 {
    font-family: cobane;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.25rem;
    color: $dark;

    @include media-breakpoint-down(lg) {
      font-size: 1.5rem;
    }
  }
}

.notifications-table {
  width: 100%;
  margin-top: 40px;

  table {
    width: 100%;
  }

  tr {
    width: 100%;
  }

  thead {
    border-bottom:  1px solid rgba(0, 0, 0, 0.13);;
  }

  th {
    padding: 20px;
    color: #8F928E;
    font-weight: 400;
    text-align: left;

  }

  td {
    padding: 20px;

    .notification-message {
      width: 100%;
      max-width: 435px;
      background: #FFFFFF;
      border: 2px solid #CCCCCC;
      border-radius: 10px;
      padding: 20px;
    }
  }
}

