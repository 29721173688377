@use 'custom'as *;
@use './components/mixins'as *;

.individual-form-box {
    @include ContentBox;    
}

.individual-form-inner-box{    
    padding: 1rem 8rem 1rem; 

    @include ContentInnerBox;
}

.individual-form-box-title {

    display: flex;
    flex-direction: row;
    align-items: center;

    h3{
        font-family: cobane;
        font-weight: 600;
        font-size: 2rem;
        color: $dark; 
        margin-bottom: 0;       
    }

    @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 0.25rem;

        h3{
            margin-top: 0.7rem;
        }
    }
}

