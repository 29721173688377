@use '../custom'as *;

@mixin ContentBox {
    margin-top: 2rem !important;
    margin-bottom: 3rem !important;

    background-color: $gray-100;
    border-radius: 2.5rem;        
}

@mixin ContentInnerBox{    

    @include media-breakpoint-down(lg) {
        padding: 1rem 1.5rem 1rem;
    }
    @include media-breakpoint-down(md) {
        padding: 1rem 1rem 1rem;
    }
}

@mixin iconStyle {

    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    border-radius: 50%;

    @include media-breakpoint-down(md) {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        margin-right: 0;
    }

    i {
        position: absolute;
        color: $white;
        font-size: 1rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    svg{
        position: absolute;
        color: $white;
        font-size: 1rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &.generate-report-icon{
            transform: scale(1.3) translate(-30%, -30%);
        }
    }

    img{
        position: absolute;
        color: $white;
        font-size: 1rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
}