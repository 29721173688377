@use "custom" as *;
@use "./components/mixins" as *;

.dashboard-credit-heavy-text{
    font-family: cobane;
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 2.5rem;
}

.dashboard-credit-styled-icon {
    @include iconStyle;
    width: 3.5rem;
    height: 3.5rem;
    margin-bottom: 1.5rem;
    background-color: #dcf0d1;    
  }