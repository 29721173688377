@use 'custom'as *;
@use './components/mixins'as *;

.merchant-form-tab{
    border-bottom: 3px solid rgba($primary, 0);
    cursor: auto;
}

.merchant-form-tab-active{
    border-bottom: 3px solid rgba($primary, 1);
    color: $primary;
}

.merchant-form-tab-passed{
    color: $dark-green;
}