@use "custom" as *;
@use "./components/mixins" as *;

.dashboard-icon-dropdown {
  position: relative;
  display: inline-block;
  height: 100%;
  padding: 1.5rem 1.1rem;

  @include media-breakpoint-down(lg){
    padding: 1.1rem 0.5rem;
  }
}

#activity-dropdown {
  border-bottom: 3px solid rgba($primary, 0);

  .dashboard-activity-icon {
    display: inline;

    svg {
      fill: $dark-50;
    }
  }

  &:hover {
    #activity-dropdown-content {
      display: block;
    }

    .dashboard-activity-icon {
      svg {
        fill: $primary;
      }
    }

    border-bottom: 3px solid rgba($primary, 1);
  }
}

.dashboard-activity-dropdown-content {
  display: none;
  position: absolute;
  transform: translateX(-20rem);
  margin-top: 1.8rem;
  background-color: $white;
  border-radius: 1.2rem;
  min-width: 26.8125rem;
  padding: 2rem 1.5rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dashboard-activity-notification-text {
  font-family: cobane;
  font-weight: 400;
  color: $dark-70;
}

.dashboard-notification-dropdown-content {
  display: none;
  position: absolute;
  transform: translateX(-20rem);
  margin-top: 1.8rem;
  background-color: $white;
  border-radius: 1.2rem;
  min-width: 26.8125rem;
  padding: 2rem 1.5rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

#notification-dropdown {
  border-bottom: 3px solid rgba($primary, 0);

  .dashboard-notification-icon {
    display: inline;

    svg {
      fill: $dark-50;
    }
  }

  &:hover {
    .dashboard-notification-dropdown-content {
      display: block;
    }

    .dashboard-notification-icon {
      svg {
        fill: $primary;
      }
    }

    border-bottom: 3px solid rgba($primary, 1);
  }
}

.dashboard-notification-item {
  border-radius: 0.7rem;

  &:hover {
    background: $dark-green-5;
  }
}

#profile-dropdown {

  border-bottom: 3px solid rgba($primary, 0);

  .dashboard-profile-icon {
    svg {
      fill: $dark-50;
    }

    i {
      color: $dark-50;
    }
  }

  &:hover {
    #profile-dropdown-content {
      display: block;
    }

    .dashboard-profile-icon {
      svg {
        fill: $primary;
      }

      i {
        color: $primary;
      }
    }
  }
}

.profile-page-active{
  border-bottom: 3px solid rgba($primary, 1) !important; 
  span {
    svg {
      fill: $primary !important;
    }

    i {
      color: $primary !important;
    }
  }
}

.dashboard-profile-dropdown-content {
  display: none;
  position: absolute;
  margin-top: 1.8rem;
  background-color: $white;
  border-radius: 0.7rem;
  min-width: 9rem;
  padding: 0.5rem 0.5rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transform: translateX(-5rem);

  @include media-breakpoint-down(lg){
    margin-top: 1.4rem;
    transform: translateX(-6.5rem);
  }
}

.dashboard-profile-dropdown-item {
  display: flex;
  align-items: center;
  padding: 0.7rem 0.5rem;
  margin-bottom: 0.3rem;
  border-radius: 0.5rem;

  svg {
    fill: $dark-50;
  }

  span {
    color: $dark-50;
    font-family: cobane;
    font-weight: 1rem;
    line-height: 1.3rem;
  }

  &:hover {
    background-color: $dark-green-5;

    svg {
      fill: $primary;
    }
    span {
      color: $primary;
    }
  }
}

.dashboard-logout{
  &:hover {
    cursor: pointer;
  }
}
