@use "custom" as *;
@use "./components/mixins" as *;

.navbar-light {
  background-color: $white;
}

.navbar-light .navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
  margin-right: 0rem;
  margin-left: 0rem;
  transition: all 0.15s ease-in-out;
  
  &:hover {
    color: $primary;
  }

  @include media-breakpoint-up(md) {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}



.navbar-light .navbar-nav .nav-link.active {
  border-bottom: 2px solid $primary;
  cursor: pointer;
  color: $primary;
}
