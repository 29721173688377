@use "custom" as *;
@use "./components/mixins" as *;

.dashboard-beneficiaries-tab {
  border-bottom: 3px solid rgba($primary, 0);
  cursor: pointer;

  svg {
    fill: #8f928e;
  }

  &:hover {
    color: $primary;

    svg {
      fill: $primary;
    }
  }
}

.dashboard-beneficiaries-tab-active {
  border-bottom: 3px solid $primary;
  cursor: pointer;
  color: $primary;

  svg {
    fill: $primary;
  }
}
