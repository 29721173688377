@use "../custom" as *;

.table-wrapper {
    overflow-x: auto;
    
    .table > :not(:first-child){
        border-top: 1px solid $dark-green-15;
    }
    
    .table-container {

        .table-header {
            font-style: cobane;
            font-weight: 400;
            font-size: 14px;
            line-height: 18.2px;
            color: $dark-50;
            border-bottom: 1px solid $dark-green-15;
            padding-left: 5px;
        }
        
        .table-data {
            color: $dark;
            font-style: cobane;
            font-weight: 500;
            font-size: 14px;
            line-height: 18.2px;
            padding: 15px 0 15px 5px;
            border-bottom: 1px solid $dark-green-10;

            .table-link {
                text-decoration: none;
                color: inherit;
            }

            .table-link:hover {
                color: $primary;
            }
        }
        
    }
}
