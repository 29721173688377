@use 'custom'as *;
@use './components/mixins'as *;

.account-type-box {
    @include ContentBox;
}

.account-type-content {    
    padding: 1rem 6rem 1rem; 

    @include ContentInnerBox;  

    &--individual {
        background-color: $white;
        border-radius: 2rem;
        margin-bottom: 2rem;
        padding: 1.5rem;
        border: 0.1rem solid $green-20;

        @include media-breakpoint-down(md) {
            padding: 0.5rem;
        }

        p{
            @include media-breakpoint-down(md) {
                text-align: center;
            }
        }

        &:hover{
            background-color: $green-20;
        }

        &--title {
            display: flex;
            flex-direction: row;
            align-items: center;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                align-items: center;
                margin-bottom: 0.25rem;
            }

            span{
                @include iconStyle;
                background-color: $green;
            }
            
        }
    }

    &--corporate {
        background-color: $white;
        border-radius: 2rem;
        padding: 1.5rem;
        margin-bottom: 1rem;
        border: 0.1rem solid $blue-20;        

        @include media-breakpoint-down(md) {
            padding: 0.5rem;
        }

        &:hover{
            background-color: $blue-20;
        }

        p{
            @include media-breakpoint-down(md) {
                text-align: center;
            }
        }

        &--title {
            display: flex;
            flex-direction: row;
            align-items: center;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                align-items: center;
                margin-bottom: 0.25rem;
            }

            span {
                @include iconStyle;
                background: $blue;
            }
        }
    }
}