@use "custom" as *;
@use "./components/mixins" as *;

.merchant-add-attendant-div{
    border-radius: 1.3rem;
    background-color: $green-5;
    height: 100%;

    span{
        @include iconStyle;
        width: 2rem;
        height: 2rem;
        background-color: $primary;

        i{
            color: white;            
        }        
    }
    
}