@use "../custom" as *;

.styled-input {
  color: $dark;
  font-family: cobane;
  font-weight: 400;
  font-size: 1.1rem;
  background-color: $white;
  height: 3.5rem;

  &::-webkit-input-placeholder {
    color: $dark-25;
    font-size: 1rem;
    font-family: cobane;
  }
}

.attendant-styled-input {
  color: $dark;
  font-family: cobane;
  font-weight: 400;
  font-size: 1.8rem;
  background-color: $white;
  height: 5rem;

  &::-webkit-input-placeholder {
    color: $dark-25;
    font-size: 1.75rem;
    font-family: cobane;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $dark-25;
    font-size: 1.75rem;
    font-family: cobane;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $dark-25;
    font-size: 1.75rem;
    font-family: cobane;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: $dark-25;
    font-size: 1.75rem;
    font-family: cobane;
  }
}

.styled-label {
  color: $dark-70;
  font-family: cobane;
  font-size: 0.875rem;
  margin-bottom: 0.2rem;
}

.styled-form-error {
  color: $red;
  font-family: cobane;
  line-height: 1;
}

.react-select {
  &__control {
    height: 3.5rem;
    border-radius: 0.7rem !important;
  }

  &__value-container {
    margin-left: 0.5rem !important;
    font-family: cobane !important;
    font-size: 1.1rem;
  }

  &__menu {
    margin-left: 0.5rem !important;
    font-family: cobane !important;
    font-size: 1.1rem;
  }
}

.section-title {
  color: $dark-green;
  font-family: cobane;
  font-weight: 700;  
  font-size: 1.875rem;
  line-height: 2.0625rem;

  @include media-breakpoint-up(md) {
    line-height: 3.75rem;
    font-size: 2.625rem;
  }
}

.column-heading {
  color: $black;
  font-family: cobane;
  font-weight: 600;
  font-size: 1.25rem;
}

.accordion-custom-text {
  font-family: cobane;
  font-weight: 500;
  font-size: 20px;
  color: $dark;
  line-height: 27px;

  @include media-breakpoint-up(md){
    font-size: 24px;
    line-height: 32px;
  }
}

.general-text {
  color: $dark-70;
  font-family: cobane, Verdana, Tahoma, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.75rem;
}

.text-dark-green{
  color: $dark-green;
}

.text-success{
  color: $green;
}

.intro-bold {
  color: $dark-green;
  line-height: 48px;
  font-family: cobane;
  font-weight: 700;
  font-size: 2.5rem;

  @include media-breakpoint-up(md) {
    line-height: 78px;
    font-size: 3rem;
  }
}

.intro-text {
  color: $dark-70;  
  font-family: cobane;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 26px;

  @include media-breakpoint-up(lg) {
    line-height: 34px;
  }

}

.intro-coming-soon {
  color: $dark-50;
  font-family: cobane;
  font-weight: 400;
  font-size: 1rem;
}

.partner-stations-title {
  text-align: center;
  color: $dark;
  font-family: cobane;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.125rem;
}

.why-fuelcredit-title {
  color: $dark-70;
  font-family: cobane;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.4rem;
}
