@use 'custom'as *;
@use './components/mixins'as *;

.footer{
    @include media-breakpoint-down(lg){
        text-align: center;          
        
    }

    .column-heading{
        margin-bottom: 1.1rem !important;
    } 
}

