@use 'custom'as *;
@use './components/mixins'as *;

$medium: 900px;

.service-type-box {
  display: flex;
  flex-direction: row;
  gap: 50px;

  > div {
    border-radius: 20px;
    height: 185px;
    flex: 1;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 0 0 20px 0;
      overflow: hidden;

      @media (max-width: 576px) {
      }
    }

    @media (max-width: 576px) {
      flex: none;
    }
  }

  .service-type-fuel-credit {
    background-color: #DCF0D1;
  }

  .service-type-fuel-voucher {
    background-color: #EEFCB5;
  }

  .service-type-individual {
    background-color: #DCF0D1;
    color: #4FB518;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .service-type-corporate {
    background-color:#EFF5FF;
    color: #186CE9;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  @media (max-width: 576px) {
    flex-direction: column;
    gap: 24px;
  }

}



